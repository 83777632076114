import React, { Component } from "react";
import { callUp } from "../../Helpers.js";
import "./operation.css";

class Operation extends Component {
  render() {
    return (
      <section id="operation" className="blocks operation_block">
        <div className="wrapper operation_wrapper">
          <div className="operation_header">
            <div className="line"></div>
            <h1>технічне обслуговування 24/7</h1>
            <div className="line"></div>
          </div>

          <div className="operation_topics">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/6.png" className="operation_card_body_ico" alt="..." />
                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          Системи опалення
                        </h5>

                        <p className="card-text">
                          Повний комплекс регламентних робіт, регулювання та очищення. Початок та завершення опалювального сезону, усунення
                          аварій, заміна опалювальних пристроїв.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/3.png" className="operation_card_body_ico" alt="..." />

                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          системи хвП та гвП
                        </h5>

                        <p className="card-text">
                          Планове та аварійне обслуговування, швидке відключення пошкоджень, усунення наслідків. Проведення ремонтних робіт,
                          заміна обладнання.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/4.png" className="operation_card_body_ico" alt="..." />

                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          електромережі
                        </h5>

                        <p className="card-text">
                          Системний контроль електрообладнання, проведення всіх необхідних замірів, функції відповідального за
                          електрогосподарство.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/2.png" className="operation_card_body_ico" alt="..." />
                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          теплові пункти
                        </h5>

                        <p className="card-text">
                          Підготовка до опалювального сезону, налагоджувальні роботи, регулювання якості теплоносія, відключення під час
                          міських аварій. Експлуатація приладів обліку тепла.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/5.png" className="operation_card_body_ico" alt="..." />

                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          насосні станції
                        </h5>

                        <p className="card-text">
                          Контроль витрат питної води, регулювання та діагностування несправностей насосної станції, усунення дефектів та
                          аварійних ситуацій. Повний комплекс регламентних робіт.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="card mb-4  operation_card">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-12">
                      <div className="card-body">
                        <img src="./img/icons/1.png" className="operation_card_body_ico" alt="..." />

                        <h5 className="card-title">
                          <img src="./img/icons/00.png" className="operation_card_header_ico" alt="..." />
                          системи вентиляції
                        </h5>

                        <p className="card-text">
                          Професійне технічне обслуговування систем вентиляції та кондиціонування в рамках планово-профілактичних заходів,
                          регулювання та очищення обладнання.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="order_button order_button_2">
            <button onClick={callUp}>ЗВ'ЯЗОК З НАМИ</button>
          </div>
          <br />
        </div>
      </section>
    );
  }
}

export default Operation;
