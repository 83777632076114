import React, { Component } from "react";
import Header from "./Header";
import About from "./About";
import Documents from "./Documents";
import Designing from "./Designing";
import Construction from "./Construction";
import Operation from "./Operation";
import Testimonials from "./Testimonials";
import Contacts from "./Contacts";
import UpButton from "./UpButton";

class Main extends Component {
  render() {
    return (
      <main>
        <UpButton />

        {/* Header */}
        <Header />

        {/* About */}
        <About />

        {/* Documents */}
        <Documents />

        {/* Designing */}
        <Designing />

        {/* Construction */}
        <Construction />

        {/* Operation */}
        <Operation />

        {/* Testimonials */}
        <Testimonials />

        {/* Contacts */}
        <Contacts />
      </main>
    );
  }
}

export default Main;
