import React, { Component } from "react";
import { callUp } from "../../Helpers.js";

import "./construction.css";

class Construction extends Component {
  render() {
    return (
      <section id="construction" className="blocks construction_block">
        <div className="wrapper construction_wrapper">
          <div className="construction_header">
            <div className="line"></div>
            <h1>обладнання, яке ми монтуємо:</h1>
            <div className="line"></div>
          </div>

          <div className="construction_topics">
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="card construction_card mb-4">
                  <img src="./img/construction/1.jpg" className="card-image img-fluid rounded" alt="..." />
                  <div className="hover-text">
                    <img className="hover_ico" src="./img/icons/00.png" alt="" />
                    <br />
                    <br />
                    <p>
                      інженерні мережі: <br /> теплові, водопровідні, побутової та дощової каналізації.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card construction_card mb-4">
                  <img src="./img/construction/4.jpg" className="card-image img-fluid rounded" alt="..." />
                  <div className="hover-text">
                    <img className="hover_ico" src="./img/icons/00.png" alt="" />
                    <br />
                    <br />
                    <br />
                    <p>системи вентиляції.</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card construction_card mb-4">
                  <img src="./img/construction/3.jpg" className="card-image img-fluid rounded" alt="..." />
                  <div className="hover-text">
                    <img className="hover_ico" src="./img/icons/00.png" alt="" />
                    <br />
                    <br />
                    <br />
                    <p>системи опалення, водопостачання та каналізації, внутрішні електромережі.</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card construction_card mb-4">
                  <img src="./img/construction/2.jpg" className="card-image img-fluid rounded" alt="..." />
                  <div className="hover-text">
                    <img className="hover_ico" src="./img/icons/00.png" alt="" />
                    <br />
                    <br />
                    <br />
                    <p>теплові пункти та насосні станції.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order_button order_button_2">
            <button onClick={callUp}>ЗВ'ЯЗОК З НАМИ</button>
          </div>
          <br />
        </div>
      </section>
    );
  }
}

export default Construction;
