import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonials.css";

class Testimonials extends Component {
  render() {
    return (
      <header id="testimonials" className="blocks testimonials_block">
        <div className="testimonials_wrapper">
          <div className="testimonials_header">
            <div className="line"></div>
            <h1>ВІДГУКи</h1>
            <div className="line"></div>
          </div>

          <div className="testimonials_info">
            <Carousel
              autoPlay
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              useKeyboardArrows
              transitionTime={1000}
              preventMovementUntilSwipeScrollTolerance={true}
              width="80%"
            >
              <div className="slide-holder">
                <a href="./img/testimonials/3d2f96123f824f24a8065f6bf0c57aa4.jpg" data-fancybox="" data-caption='ТОВ "УКРМІСЬКБУД ХОЛДИНГ"'>
                  <img src="./img/testimonials/preview/3d2f96123f824f24a8065f6bf0c57aa4.jpg" />
                </a>
                <div className="text-container">
                  <h1>ТОВ "укрміськбуд холдинг"</h1>
                  <h2>Будівельна компанія</h2>
                  <p>
                    "ТУ КОНСАЛТИНГ надали послуги з підключення до мереж водопостачання та водовідведення м.Києва ( отримання технічних умов
                    на підключення, розробка та погодження проекту, будівництво водопровідних і каналізаційних мереж, заключення договору з
                    ПрАТ Київводоканал)."
                  </p>
                </div>
              </div>
              <div className="slide-holder">
                <a href="./img/testimonials/ce57016d3bab005b661007ac88f2b9d0.jpg" data-fancybox="" data-caption='ТОВ "ПМ БУДКОМСЕРВІС"'>
                  <img src="./img/testimonials/preview/ce57016d3bab005b661007ac88f2b9d0.jpg" />
                </a>
                <div className="text-container">
                  <h1>ТОВ "ПМ БУДКОМСЕРВІС"</h1>
                  <h2>Приватна керуюча компанія</h2>
                  <p>
                    "Фахівці ТУ КОНСАЛТИНГ уклали договори на теплопостачання, водопостачання та водовідведення, електропостачання для
                    офісної будівлі в м.Києві. Технічне обслуговування внутрібудинкових систем."
                  </p>
                </div>
              </div>
              <div className="slide-holder">
                <a href="./img/testimonials/644f465bb0ce617cd886b4ac827e2161.jpg" data-fancybox="" data-caption="ТОВ СБ ЛТД">
                  <img src="./img/testimonials/preview/644f465bb0ce617cd886b4ac827e2161.jpg" />
                </a>
                <div className="text-container">
                  <h1>тов сб лтд</h1>
                  <h2>Будівельна компанія</h2>
                  <p>
                    "Компанія «ТУ КОНСАЛТИНГ» виконала будівництво модульної газовой котельні на 8 МВт та будівництво теплової мережі Ду300
                    мм для підключення житлового комплексу в м. Київ пр.Науки,55."
                  </p>
                </div>
              </div>
              <div className="slide-holder">
                <a href="./img/testimonials/aa0c4080893a80e56a739f7493885a7a.jpg" data-fancybox="" data-caption="ЯРОСЛАВ ЩЕРБАК">
                  <img src="./img/testimonials/preview/aa0c4080893a80e56a739f7493885a7a.jpg" />
                </a>
                <div className="text-container">
                  <h1>ярослав щербак</h1>
                  <h2>Фізична особа</h2>
                  <p>
                    "Спеціалісти ТУ КОНСАЛТИНГ забезпечили будівництво індівідуального житлового будинку підключенням до міських мереж та
                    дозвільними документами."
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </header>
    );
  }
}

export default Testimonials;
