import React, { Component } from "react";
import "./designing.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
class Designing extends Component {
  render() {
    return (
      <section id="designing" className="blocks designing_block">
        <div className="designing_wrapper">
          <div className="designing_info">
            <h3>проектування</h3>
            <div className="line"></div>
            <br />
            <AnimationOnScroll animateOnce offset={30} animateIn="animate__rollIn">
              <p>
                <span>Компанія «ТУ КОНСАЛТИНГ»</span> — висококваліфіковані фахівці своєї справи:
              </p>
            </AnimationOnScroll>

            <AnimationOnScroll animateOnce offset={30} animateIn="animate__rollIn">
              <p>- сертифіковані проектанти реалізують будь-які побажання замовника;</p>
              <p>- проектування зовнішних та внутрібудинкових інженерних мереж, теплових пунктів та насосних станцій;</p>
              <p>- підбір найкращого обладнання від виробників;</p>
              <p>
                - багаторічний досвід співпраці з Київгаз, Київенерго, Київтеплоенерго, Київоблгаз, Київводоканал, Обленерго та інші
                забезпечує погодження проектної та дозвільної документації в короткі терміни.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
    );
  }
}

export default Designing;
