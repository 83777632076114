import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./about.css";

class About extends Component {
  render() {
    return (
      <section id="about" className="blocks about_block">
        <div className="wrapper about_wrapper">
          <div className="about_header">
            <div className="line"></div>
            <h1>ТУ Консалтинг</h1>
            <div className="line"></div>
          </div>
          <div className="about_subheader">
            <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
              <p>інжинірингова компанія повного циклу з проектування, будівництва та експлуатації інженерних мереж та обладнання.</p>
            </AnimationOnScroll>
          </div>

          <div className="about_topics">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">інженерна підготовка</h4>
                  <p className="description">
                    Для економічно-вигідного підключення об'єктів до мереж водо-газо-тепло-електопостачання, наші спеціалісти проводять
                    комплексне обстеження наявної інженерної інфраструктури. Звіт з можливості підключення.
                  </p>
                </AnimationOnScroll>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">дозвільна документація</h4>
                  <p className="description">
                    Сертифіковані фахівці отримають технічні умови на підключення будинків, приміщень, земельних ділянок до інженерних
                    мереж. Багаторічний досвід співпраці з Київгаз, Київтеплоенерго, ДТЕК, Київоблгаз, Київводоканал, Обленерго та інші
                    дозволяє погоджувати технічну документацію в короткі терміни.
                  </p>
                </AnimationOnScroll>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">будівельно-монтажні роботи</h4>
                  <p className="description">
                    Досвідчені спеціалісти виконають будівельно-монтажні роботи інженерних мереж та обладнання любої складності в терміни,
                    що передбачені договором. Пуско-налагоджувальні роботи змонтованого обладнання, передача в експлуатацію.
                  </p>
                </AnimationOnScroll>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">технічне обслуговування</h4>
                  <p className="description">
                    Дільниця з експлуатації візьме на гарантійне та післягарантійне обслуговування внутрібудинкові системи опалення,
                    водопостачання та побутової каналізації, внутрішні електромережі, теплові пункти та насосні станції.
                  </p>
                </AnimationOnScroll>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">укладання договорів</h4>
                  <p className="description">
                    Виконуємо укладання прямих договорів з Київгаз, Київенерго, ДТЕК, Київтеплоенерго, Київводоканал, Київоблгаз, Обленерго
                    тощо. Тимчасові договори для проведення пусконалагоджувальних робіт.
                  </p>
                </AnimationOnScroll>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 topic-card">
                <AnimationOnScroll offset={50} animateOnce animateIn="animate__zoomIn">
                  <h4 className="spec_color title">переваги співпраці</h4>
                  <p className="description">
                    Повний перелік послуг пов'язаних з інженерною інфраструктурою для замовника в одному місці. Безкоштовні консультації,
                    гарантія дотримання термінів, представлення інтересів, повна конфіденційність.
                  </p>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
