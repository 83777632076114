import React, { Component } from "react";
import { callUp } from "../../Helpers.js";
import "./header.css";

class Header extends Component {
  state = {
    openMenu: false
  };

  render() {
    return (
      <header id="home" className="blocks block-header" onClick={this.hideMenu}>
        <div className="block-header__wrapper">
          <div className="block-header__topbar">
            <div className="block-header__logo">
              <img src="./img/icons/00.png" alt="" />
              <span>ту консалтинг</span>
            </div>
            <div className="block-header__nav">
              <nav className={this.state.openMenu ? "topnav responsive" : "topnav"} id="myTopnav">
                <a onClick={this.onClickAbout.bind(this)}>ПРО НАС</a>
                <a onClick={this.onClickDocuments.bind(this)}>ДОЗВІЛЬНА ДОКУМЕНТАЦІЯ</a>
                <a onClick={this.onClickDesigning.bind(this)}>ПРОЕКТУВАННЯ</a>
                <a onClick={this.onClickConstruction.bind(this)}>БУДІВНИЦТВО</a>
                <a onClick={this.onClickOperation.bind(this)}>ЕКСПЛУАТАЦІЯ</a>
                <a onClick={this.onClickTestimonials.bind(this)}>ВІДГУКИ</a>
                <a onClick={this.onClickContacts.bind(this)}>КОНТАКТЫ</a>
              </nav>
            </div>
            <div className="block-header__menu-button">
              <a className="menuButton" href={null} onClick={this.showMenu}>
                <i className="fa fa-bars"></i>
              </a>
            </div>
          </div>

          <div className="block-header__content">
            <div className="block-header__main-text animate__animated animate__backInLeft">
              <h1>
                <strong>
                  Проектування, будівництво та технічна експлуатація <br />
                  <span className="spec_color">інженерних мереж та обладнання.</span>
                </strong>
              </h1>
              <br />
            </div>

            <div className="block-header__second-text animate__animated animate__zoomIn">
              <h1>
                індивідуальний підхід до кожного Замовника, <span></span>
                будь-то промисловий об’єкт чи приватне житло.
              </h1>
              <br />
              <br />
            </div>

            <div className="block-header__third-text animate__animated animate__backInLeft">
              <h1>
                послуги спеціалістів з отримання дозвільної документації <span></span> для підключення об'єкта до інженерних мереж.
              </h1>
            </div>
          </div>

          <div className="block-header__footer">
            <div className="block-header__contact-button animate__animated animate__backInUp">
              <button onClick={callUp}>
                <i className="fa fa-phone"></i>&nbsp;&nbsp; ЗВ'ЯЗОК З НАМИ
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }

  hideMenu = (e) => {
    e && e.preventDefault();
    if (e.target.className !== "fa fa-bars") {
      this.setState({
        openMenu: false
      });
    }
  };

  showMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu
    });
  };

  onClickAbout(e) {
    e && e.preventDefault();
    document.getElementById("about").scrollIntoView();
  }

  onClickDocuments(e) {
    e && e.preventDefault();
    document.getElementById("documents").scrollIntoView();
  }

  onClickDesigning(e) {
    e && e.preventDefault();
    document.getElementById("designing").scrollIntoView();
  }

  onClickConstruction(e) {
    e && e.preventDefault();
    document.getElementById("construction").scrollIntoView();
  }

  onClickOperation(e) {
    e && e.preventDefault();
    document.getElementById("operation").scrollIntoView();
  }

  onClickTestimonials(e) {
    e && e.preventDefault();
    document.getElementById("testimonials").scrollIntoView();
  }

  onClickContacts(e) {
    e && e.preventDefault();
    document.getElementById("contacts").scrollIntoView();
  }
}

export default Header;
