import React, { Component } from "react";
import { callUp } from "../../Helpers.js";
import "./documents.css";

class Documents extends Component {
  render() {
    return (
      <section id="documents" className="blocks documents_block">
        <div className="wrapper documents_wrapper">
          <div className="documents_header">
            <div className="line"></div>
            <h1>документи, що ви отримаєте:</h1>
            <div className="line"></div>
          </div>

          <div className="documents_topics">
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="card mb-4">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-4">
                      <a
                        href="./img/docs/doc-1-example.jpg"
                        data-fancybox=""
                        data-caption="Технічні умови та договір приєднання до теплових, газових, водопровідних, електричних мереж."
                      >
                        <img src="./img/docs/doc-1-example.jpg" className="img-fluid rounded-start" alt="..." />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text">
                          Технічні умови та договір приєднання до теплових, газових, водопровідних, електричних мереж.
                        </p>
                        <div className="order_button">
                          <button onClick={callUp}>замовити</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card mb-4">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-4">
                      <a href="./img/docs/doc-2-example.jpg" data-fancybox="" data-caption="Контрольна картка на порушення благоустрою.">
                        <img src="./img/docs/doc-2-example.jpg" className="img-fluid rounded-start" alt="..." />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text">Контрольна картка на порушення благоустрою.</p>
                        <div className="order_button">
                          <button onClick={callUp}>замовити</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card mb-4">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-4">
                      <a
                        href="./img/docs/doc-3-example.jpg"
                        data-fancybox=""
                        data-caption="Прямий договір на тепло, водо, електо, газопостачачання."
                      >
                        <img src="./img/docs/doc-3-example.jpg" className="img-fluid rounded-start" alt="..." />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text">Прямий договір на тепло, водо, електо, газопостачачання.</p>
                        <div className="order_button">
                          <button onClick={callUp}>замовити</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6">
                <div className="card mb-4">
                  <div className="row g-0 vcenter-item">
                    <div className="col-md-4">
                      <a href="./img/docs/doc-4-example.jpg" data-fancybox="" data-caption="Акт введення в експлуатацію від контролюючих органів.">
                        <img src="./img/docs/doc-4-example.jpg" className="img-fluid rounded-start" alt="..." />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text">Акт введення в експлуатацію від контролюючих органів.</p>
                        <div className="order_button">
                          <button onClick={callUp}>замовити</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Documents;
