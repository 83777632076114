import React, { Component } from "react";

import "./contactForm.css";

class Contacts extends Component {
  render() {
    return (
      <section id="contacts" className="blocks contacts_block">
        <div className="contacts_wrapper">
          <div className="contacts_info">
            <div className="phone_ico animate__animated animate__pulse animate__infinite infinite">
              <a data-action="link" href="tel: 380 (63) 117 13 74" target="_self">
                <h1>
                  <i className="fa fa-phone-2"></i>
                </h1>
              </a>
            </div>
            <br />
            <br />
            <a
              data-action="linknew"
              href="https://goo.gl/maps/xoDhgjnd84SGTewY6"
              target="_blank"
            >
              <h4>
                <i className="fa fa-globe"></i>
                <span>&nbsp;&nbsp; м.Київ. вул. О. Копиленка, 4 офіс 1308</span>
              </h4>
            </a>

            <a data-action="link" href="tel: 380 (63) 117 13 74" target="_self">
              <h4>
                <i className="fa fa-phone"></i>
                <span>&nbsp;&nbsp; 380 (63) 117 13 74</span>
              </h4>
            </a>

            <a data-action="link" href="mailto: tu-konsalting@ukr.net" target="_blank">
              <h4>
                <i className="fa fa-envelope"></i>
                <span>&nbsp;&nbsp; tu-konsalting@ukr.net</span>
              </h4>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
