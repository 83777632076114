import React, { Component, useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./animate.css";

import Main from "./components";

class App extends Component {
  // componentDidMount() {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 3000);
  // }

  render() {
    return (
      <React.Fragment>
        <Main />
      </React.Fragment>
    );
  }
}

export default App;
