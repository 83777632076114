import React, { Component } from "react";

import "./upButton.css";

window.onscroll = function () {
  const scrolled = window.pageYOffset || document.documentElement.scrollTop;
  const topButton = document.getElementById("upbutton");
  scrolled > 200 ? (topButton.className = "class2") : (topButton.className = "class1");
};

class UpButton extends Component {
  onClickHome(e) {
    e && e.preventDefault();
    document.getElementById("home").scrollIntoView();
  }
  render() {
    return (
      <a className="class1" id="upbutton" onClick={this.onClickHome.bind(this)}>
        <img src="./img/icons/up1.png" alt="Top" border="none" title="На початок" />
      </a>
    );
  }
}

export default UpButton;
